import React, { useEffect, useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/img-slider-home.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../News/scrollbar.css";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Rating,
    TextareaAutosize,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";
import altitudine from "../assets/imgTrek/altitudine.svg";
import auto from "../assets/imgTrek/auto.svg";
import bussola from "../assets/imgTrek/bussola.svg";
import clock from "../assets/imgTrek/clock.svg";
import destinazione from "../assets/imgTrek/destinazione.svg";
import dislivelloMax from "../assets/imgTrek/dislivelloMax.svg";
import dislivelloMin from "../assets/imgTrek/dislivelloMin.svg";
import lunghezza from "../assets/imgTrek/lunghezza.svg";
import mappa from "../assets/imgTrek/mappa.svg";
import percorso from "../assets/imgTrek/percorso.svg";
import tenda from "../assets/imgTrek/tenda.svg";
import trekFoto from "../assets/imgTrek/trek.svg";
import zaino from "../assets/imgTrek/zaino.svg";
import info from "../assets/imgTrek/info.svg";
import pdf from "../assets/imgTrek/pdf.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ItemAccompagnatori from "../components/ItemAccompagnatori";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
import "leaflet/dist/leaflet.css";
import MapContainer from "./MapContainer";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import renderHTML from "react-render-html";
import parse from 'html-react-parser';
import _ from "lodash";
import moment from "moment";
import "moment/locale/it";
import Login from "../Auth/Login";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import InputGimlet from "../components/InputGimlet";
import InputBilo from "../components/InputBilo";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from './Trek.module.css'
import InputCustom from "../components/InputCustom";
import DialogCustom from "../components/DialogCustom";
import env from "../conf/variables";
const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  border: 1px solid black;
  box-shadow: 0px 2px 2px grey;

  &:hover {
    border-color: blue;
  }

  &:focus {
    border-color: blue;
    box-shadow: 0 2px 5px blue;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const leggiDiPiu = '... Leggi di più'
const validationSchema = yup.object({
    info: yup.string().required("Campo obbligatorio"),
});

const Trek = ({ count }) => {
    const [readMore, setReadMore] = useState(false)
    const [openAskInfo, setOpenAskInfo] = useState(false);
    const [openAlertForm, setOpenAlertForm] = useState({ open: false, message: '' });
    const [openRisposta, setOpenRisposta] = useState();
    const [openIscriviti, setOpenIscriviti] = useState(false)
    const [openListaDAttesa, setOpenListaDAttesa] = useState(false)
    const [openPrenotati, setOpenPrenotati] = useState(false)
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false)
    const [openAnticipoOrSaldo, setOpenAnticipoOrSaldo] = useState(false)
    const [userSelected, setUserSelected] = useState(null)
    const [idMultiIscrizioni, setIdMultiIscrizioni] = useState([])
    const [openFormSearchUser, setOpenFormSearchUser] = useState(false)
    const [usersFound, setUsersFound] = useState([])
    const [importo, setImporto] = useState(0)
    const [isAnticipo, setIsAnticipo] = useState(false)
    const [searchUser, setSearchUser] = useState({
        nome: '',
        cognome: '',
        email: '',
        codice_fiscale: ''
    })
    const [error, setError] = useState(
        {
            open: false,
            error: ''
        }
    )
    const [success, setSuccess] = useState(
        {
            open: false,
            message: ''
        }
    )

    const [isIphone, setIsIphone] = React.useState(false)
    const [linkForIphone, setLinkForIphone] = React.useState(null)

    const location = useLocation()
    const navigate = useNavigate();
    const { id } = useParams();
    L.Marker.prototype.options.icon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
    });

    const { token, profile } = useSelector((rootState) => rootState.count);

    api.setHeaders({
        Authorization: "Bearer " + token,
    });

    const fetcher = (url) => api.get(url).then((res) => {
        document.getElementById('main_title').innerText = res && res.data && res.data.data && res.data.data.title && res.data.data.format ? `${res.data.data.title} | ${res.data.data.format !== 'Grande Trek' ? ('Trek ' + res.data.data.format) : res.data.data.format} | Trekking Italia` : 'Trekking Italia'
        return res
    });
    useEffect(() => {
        return () => {
            document.getElementById('main_title').innerText = 'Trekking Italia'
        }
    }, [])
    const { data: trek } = useSWR([`/get-trek/` + id], fetcher);

    const fetcher2 = (url) =>
        api.post(url, { trek_id: id }).then((res) => res.data.data);
    const { data: ask, error: askError } = useSWR([`/get-answer`], fetcher2);

    const ItemImageTrek = ({ title, data, img, voto, unita }) => {
        const StyledRating = styled(Rating)({
            "& .MuiRating-iconFilled": {
                color: "#796557",
            },
        });

        moment.locale("it");
        if (data === '') {
            return ''
        }
        return (
            <div className={"flex flex-row items-center gap-2 text-[#796557]"}>
                <img src={img} className={"w-12 h-12 m-2"} alt={title} />
                <div className={"flex flex-col"}>
                    <div
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                        className={"text-xl"}>
                        {title} {unita ? `(${unita})` : ''}
                    </div>
                    {voto ? (
                        <StyledRating
                            disabled
                            defaultValue={data}
                            precision={0.5}
                            icon={<FavoriteIcon fontSize="inherit" />}
                            emptyIcon={
                                <FavoriteIcon sx={{ color: "#c9c1bc" }} fontSize="inherit" />
                            }
                        />
                    ) : (
                        <div
                            style={{ fontFamily: "gimlet-text" }}
                            className={"text-2xl capitalize"}>
                            {data}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            info: "",
        },
        validationSchema: validationSchema,
        onSubmit: (value) => {
            api
                .post("/users/question-add", { info: value.info, trek_id: trek && trek.data && trek.data.data ? trek.data.data.trek_subscription_id : '' })
                .then((res) => {
                    if (!res.ok) {
                        setOpenAlertForm({ open: true, message: "Si è verificato un errore, riprova!" });
                    } else {
                        setOpenAlertForm({ open: true, message: "Richiesta inviata." });
                    }

                    setOpenAskInfo(false);
                })
                .catch((err) => {
                    console.log(err);
                    setOpenAlertForm({ open: true, message: 'Si è verificato un errore, riprova!' });
                    setOpenAskInfo(false);
                });
        },
    });

    function handleClickPagamento(payment_method = '') {
        api.post('subscriptions/add-subscription-without-payment', {
            user_id: count.profile.id,
            id: trek && trek.data && trek.data.data ? trek.data.data.trek_subscription_id : '',
            type: 'trek',
            userIDs: idMultiIscrizioni.map(user => user.id),
            balance: importo,
            payment_method: payment_method
        }).then(res => {
            if (res.data.errors) {
                setError({
                    open: true,
                    error: res.data.errors.user[0]
                })
            } else {
                setSuccess({
                    open: true,
                    message: 'La informiamo che ha effettuato correttamente l\'iscrizione'
                })
            }

            return res
        })
        setOpenIscriviti(false);
    }

    function handleDownloadProgramma() {
        api.get(`/get-pdf-trek/${id}`).then((res) => {
            let href = `data:application/octet-stream;base64,${res && res.data && res.data.data}`
            var a = document.createElement('a');
            a.href = href;
            a.target = '_blank';
            a.download = `Programma_Giornaliero_${id}.pdf`;
            a.click();
        })
    }

    if (trek && !trek.ok) return <ErrorsComponent />;
    if (!trek || !ask) return <LoadingComponent />;

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                show="false"
                title={trek && trek.data && trek.data.data && trek.data.data.title ? trek.data.data.title : ""}
                img={trek && trek.data && trek.data.data && trek.data.data.img_testata ? trek.data.data.img_testata : img1}
                position={"center"}
                classNameTitle="text-lg sm:text-3xl lg:text-6xl"
            />
            <div
                className={"bg-white relative z-40 p-0 sm:p-1 md:p-8 py-20 flex flex-col"}
                style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}>
                <div className={"flex flex-row items-center gap-1"}>
                    <div
                        className={"cursor-pointer hover:opacity-50"}
                        onClick={() => navigate(-1)}>
                        Home {">"}
                    </div>
                    <div className={"font-bold"}> Trek</div>
                </div>
                <div
                    id="main"
                    className={"grid lg:grid-cols-3 grid-cols-1 gap-2"}>
                    {/* PRIMA COLONNA */}
                    <div className={"flex flex-col lg:col-span-2 col-start-1 row-start-1 p-2 sm:p-0"}>
                        {/* SOTTOTITOLO */}
                        <InputGimlet
                            wrapperTag="h3"
                            title={trek && trek.data && trek.data.data && trek.data.data.subtitle ? trek.data.data.subtitle : ""}
                            fontStyle="italic"
                            fontWeight={700}
                            className={"text-[#796557] flex flex-col text-2xl mt-12 gap-4"}
                        />
                        {/* TREK TYPE E SEZIONE ORGANIZZATRICE */}
                        <div
                            className={"flex flex-col lg:flex-row items-center gap-8 my-12"}>
                            <div className={"pr-4 border-r-2 border-[#796557]"}>
                                <ItemImageTrek
                                    img={mappa}
                                    title={"Trek"}
                                    data={trek && trek.data && trek.data.data && trek.data.data.format ? trek.data.data.format : ""}
                                />
                            </div>
                            <ItemImageTrek
                                img={percorso}
                                title={"Sez. Organizzatrice"}
                                data={trek && trek.data && trek.data.data && trek.data.data.dname ? trek.data.data.dname : ""}
                            />
                        </div>
                        {/* DATA DI PARTENZA, PUNTO DI RITROVO, DATA DI RITROVO */}
                        <div
                            className={
                                "text-2xl gap-2 flex flex-col w-full rounded-xl p-4 bg-[#f0e9e3]"
                            }>
                            <div
                                className={"text-[#796557]"}
                                style={{ fontFamily: "gimlet-text" }}>
                                <h3 className="inline-flex">Data di partenza:</h3>
                                {" "}
                                <InputGimlet
                                    wrapperTag="h3"
                                    title={trek && trek.data && trek.data.data && trek.data.data.date_start_schedulation
                                        ? moment(trek.data.data.date_start_schedulation).format("LL")
                                        : ""}
                                    fontWeight={700}
                                    className={"inline-flex"}
                                />
                            </div>
                            <div
                                className={"text-[#796557]"}
                                style={{ fontFamily: "gimlet-text" }}>
                                <h3 className="inline-flex">Punto di ritrovo:</h3>
                                {" "}
                                <InputGimlet
                                    wrapperTag="h3"
                                    fontWeight={700}
                                    className={"inline-flex "}
                                    title={trek && trek.data && trek.data.data && trek.data.data.route
                                        ? trek.data.data.route[0].locality
                                        : ""}
                                />
                            </div>
                            <div
                                className={"text-[#796557]"}
                                style={{ fontFamily: "gimlet-text" }}>
                                <h3 className="inline-flex">Data di ritorno:</h3>
                                {" "}
                                <InputGimlet
                                    wrapperTag="h3"
                                    fontWeight={700}
                                    className={"inline-flex"}
                                    title={trek && trek.data && trek.data.data && trek.data.data.date_start_schedulation && trek.data.data.technical && trek.data.data.technical.trek_duration_gg
                                        ? moment(new Date(new Date(trek.data.data.date_start_schedulation).getTime() + (trek.data.data.technical.trek_duration_gg > 1 ? ((trek.data.data.technical.trek_duration_gg - 1) * 86400000) : 0))).format("LL")
                                        : ""}
                                />
                            </div>
                        </div>
                        {/* LOCALITA', FORMATO, DURATA, TIPOLOGIA, CATEGORIA, DIFFICOLTA' */}
                        <div
                            className={
                                "lg:grid flex flex-col lg:grid-cols-2 xl:grid-cols-3 lg:grid-rows-3 gap-8 mt-8"
                            }>
                            <ItemImageTrek
                                img={bussola}
                                title={"Località"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.route ? trek.data.data.route[0].countries : ""
                                }
                            />
                            <ItemImageTrek
                                img={percorso}
                                title={"Formato"}
                                data={trek && trek.data && trek.data.data && trek.data.data.format ? trek.data.data.format : ""}
                            />
                            <ItemImageTrek
                                img={clock}
                                title={"Durata"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.trek_duration_hh && trek.data.data.technical.trek_duration_gg
                                    ? trek.data.data.technical.trek_duration_gg > 1 ?
                                        trek.data.data.technical.trek_duration_gg + ' giorni'
                                        : trek.data.data.technical.trek_duration_hh + 'h'
                                    : ""
                                }
                            />
                            <ItemImageTrek
                                img={mappa}
                                title={"Tipologia"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.type && trek.data.data.type[0].name
                                    ? trek.data.data.type[0].name
                                    : ""
                                }
                            />
                            <ItemImageTrek
                                img={zaino}
                                title={"Categoria"}
                                data={trek && trek.data &&
                                    trek.data.data && trek.data.data.category_id
                                    ? trek.data.data.category_id.name
                                    : ""
                                }
                            />
                            <ItemImageTrek
                                img={trekFoto}
                                title={"Difficoltà"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.difficulty
                                    ? trek.data.data.technical.difficulty.name
                                    : ""
                                }
                            />
                        </div>
                        {/* QUOTE */}
                        <div className="p-2 bg-[#e7ded5] pt-6">
                            <div className="flex flex-row items-center gap-2">
                                <InputBilo
                                    wrapperTag="h2"
                                    title={"QUOTA DI PARTECIPAZIONE "}
                                    fontWeight={700}
                                    className={"inline-flex text-3xl text-[#796557]"}
                                />
                                <InputBilo
                                    wrapperTag="h2"
                                    title={trek && trek.data && trek.data.data && trek.data.data.schedulation
                                        && trek.data.data.opening_status !== 'preview' ?
                                        `${trek.data.data.schedulation.quote_trek ? `€ ${trek.data.data.schedulation.quote_trek}` : 'Gratuito'}` : 'Da definire'}
                                    fontWeight={700}
                                    className={"text-3xl  text-[#DF5B23]"}
                                />
                            </div>
                            {/* <InputBilo
                                className={"underline text-[#796557] text-2xl"}
                                title="* leggi le note sulla composizione delle quota"
                            /> */}
                        </div>
                        <div className="p-2 pb-6 bg-[#f2ede8]">
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    <h4 className="inline-flex">Stato del trek:</h4>
                                    {" "}
                                    <InputBilo
                                        wrapperTag="h4"
                                        fontWeight={700}
                                        className={"inline-flex"}
                                        title={
                                            //Status === canceled? Cancellato
                                            trek && trek.data && trek.data.data && trek.data.data.status && trek.data.data.status === "canceled"
                                                ? "Cancellato"
                                                : (
                                                    //data di inizio <= oggi ? 
                                                    trek.data.data.date_start_schedulation && new Date(trek.data.data.date_start_schedulation) <= new Date() ?
                                                        (
                                                            trek.data.data.technical.trek_duration_gg && new Date() <= new Date(new Date(trek.data.data.date_start_schedulation).getTime() + (trek.data.data.technical.trek_duration_gg * 86400000)) ?
                                                                'Partito'
                                                                : 'Concluso'
                                                        )
                                                        : "Attivo"
                                                )
                                        }
                                    />
                                </div>
                            </div>
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    <h4 className="inline-flex">Data chiusura iscrizioni:</h4>
                                    {" "}
                                    <InputBilo
                                        wrapperTag="h4"
                                        fontWeight={700}
                                        className={"inline-flex"}
                                        title={trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.closing_date_registration
                                            ? moment(trek.data.data.schedulation.closing_date_registration).format(
                                                "DD-MM-YYYY"
                                            )
                                            : ""}
                                    />
                                </div>
                            </div>
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    <h4 className="inline-flex">Numero minimo partecipanti:</h4>
                                    {" "}
                                    <InputBilo
                                        fontWeight={700}
                                        className={"inline-flex"}
                                        title={trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                            trek.data.data.schedulation.minimum_participants &&
                                            trek.data.data.schedulation.minimum_participants}
                                    />
                                </div>
                            </div>
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    <h4 className="inline-flex">Numero massimo partecipanti:</h4>
                                    {" "}
                                    <InputBilo
                                        fontWeight={700}
                                        className={"inline-flex"}
                                        title={trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                            trek.data.data.schedulation.maximum_participants &&
                                            trek.data.data.schedulation.maximum_participants}
                                    />
                                </div>
                            </div>
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    <h4 className="inline-flex">Gestione della quota:</h4>
                                    {" "}
                                    <InputBilo
                                        wrapperTag="h4"
                                        fontWeight={700}
                                        className={"inline-flex"}
                                        title={trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quota_management &&
                                            (trek.data.data.schedulation.quota_management === 'advance_balance' ?
                                                'Anticipo + Saldo'
                                                : (trek.data.data.schedulation.quota_management === 'balance' ?
                                                    'Solo saldo'
                                                    : (trek.data.data.schedulation.quota_management === 'free' ?
                                                        'Gratuita'
                                                        : '')))
                                        }
                                    />
                                </div>
                            </div>
                            {trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                trek.data.data.schedulation.quota_management === 'advance_balance' &&
                                <>
                                    {
                                        trek.data.data.opening_status !== 'preview' &&
                                        <>
                                            <div className={"flex flex-row items-center"}>
                                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                                <div
                                                    style={{
                                                        fontFamily: "bilo",
                                                        fontWeight: 500,
                                                        fontStyle: "normal",
                                                    }}
                                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                                    <h4 className="inline-flex">Anticipo quota:</h4>
                                                    {" "}
                                                    <InputBilo
                                                        fontWeight={700}
                                                        className={"inline-flex"}
                                                        title={'€' +
                                                            (trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                                                trek.data.data.schedulation.advance_fee &&
                                                                trek.data.data.schedulation.advance_fee)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"flex flex-row items-center"}>
                                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                                <div
                                                    style={{
                                                        fontFamily: "bilo",
                                                        fontWeight: 500,
                                                        fontStyle: "normal",
                                                    }}
                                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                                    <h4 className="inline-flex">Saldo quota:</h4>
                                                    {" "}
                                                    <InputBilo
                                                        fontWeight={700}
                                                        className={"inline-flex"}
                                                        title={'€' +
                                                            (trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                                                trek.data.data.schedulation.balance_fee &&
                                                                trek.data.data.schedulation.balance_fee)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className={"flex flex-row items-center"}>
                                        <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                        <div
                                            style={{
                                                fontFamily: "bilo",
                                                fontWeight: 500,
                                                fontStyle: "normal",
                                            }}
                                            className={"ml-2 text-2xl  text-[#796557]"}>
                                            <p className="inline-flex">Modalità pagamento anticipo:</p>
                                            {" "}
                                            <InputBilo
                                                fontWeight={700}
                                                className={"inline-flex"}
                                                title={trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                                    trek.data.data.schedulation.advance_payment_methods &&
                                                    trek.data.data.schedulation.advance_payment_methods}
                                            />
                                        </div>
                                    </div>
                                    <div className={"flex flex-row items-center"}>
                                        <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                        <div
                                            style={{
                                                fontFamily: "bilo",
                                                fontWeight: 500,
                                                fontStyle: "normal",
                                            }}
                                            className={"ml-2 text-2xl  text-[#796557]"}>
                                            <p className="inline-flex">Modalità pagamento saldo:</p>
                                            {" "}
                                            <InputBilo
                                                fontWeight={700}
                                                className={"inline-flex"}
                                                title={trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                                    trek.data.data.schedulation.balance_payment_methods &&
                                                    trek.data.data.schedulation.balance_payment_methods}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                trek.data.data.schedulation.quota_management === 'balance' &&
                                <>
                                    <div className={"flex flex-row items-center"}>
                                        <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                        <div
                                            style={{
                                                fontFamily: "bilo",
                                                fontWeight: 500,
                                                fontStyle: "normal",
                                            }}
                                            className={"ml-2 text-2xl  text-[#796557]"}>
                                            <p className="inline-flex">Modalità pagamento saldo:</p>
                                            {" "}
                                            <InputBilo
                                                fontWeight={700}
                                                className={"inline-flex"}
                                                title={trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                                    trek.data.data.schedulation.balance_payment_methods &&
                                                    trek.data.data.schedulation.balance_payment_methods}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.places_available && !(trek.data.data.schedulation.places_available > (trek.data.data.schedulation.maximum_participants - trek.data.data.schedulation.minimum_participants)) ?
                                    <div className={"flex flex-row items-center"}>
                                        <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                        <div
                                            style={{
                                                fontFamily: "bilo",
                                                fontWeight: 500,
                                                fontStyle: "normal",
                                            }}
                                            className={"ml-2 text-2xl  text-[#796557]"}>
                                            <p className="inline-flex">Numero posti disponibili:</p>
                                            {" "}
                                            <InputBilo
                                                fontWeight={700}
                                                className={"inline-flex"}
                                                title={trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.places_available &&
                                                    trek.data.data.schedulation.places_available < 0 ? 0 : trek.data.data.schedulation.places_available}
                                            />
                                        </div>
                                    </div>
                                    : ''
                            }

                            <InputBilo
                                fontWeight={700}
                                className={"text-2xl text-[#DF5B23] mt-12"}
                                title={
                                    trek && trek.data && trek.data.data && trek.data.data.available_seats && trek.data.data.available_seats > 0 ?
                                        ''
                                        : `Abbiamo raggiunto il numero massimo di iscritti`
                                }

                            />
                            <InputBilo
                                fontWeight={700}
                                className={"text-2xl text-[#796557]"}
                                title={'Per ulteriori informazioni contatta la segreteria di Trekking Italia'}
                            />
                            <a
                                href={`mailto:${trek && trek.data && trek.data.data && trek.data.data.email}`}
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                }}
                                className={"text-2xl text-[#796557]"}>
                                {trek && trek.data && trek.data.data && trek.data.data.email}
                            </a>
                        </div>
                        {/* PULSANTI ISCRIONE / PRENOTAZIONE / LISTA D'ATTESA */}

                        {
                            trek && trek.data && trek.data.data && trek.data.data.users_subscribers && (count?.profile?.id in trek.data.data.users_subscribers) ?
                                <div className="pl-2 pb-2 w-full flex items-center flex-col md:flex-row bg-[#f2ede8] gap-2">
                                    <h1 className="py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit ">
                                        {
                                            trek.data.data.users_subscribers[count?.profile?.id] === 'subscriber' || trek.data.data.users_subscribers[count?.profile?.id] === 'complete' ?
                                                'Sei già iscritto'
                                                : (
                                                    trek.data.data.users_subscribers[count?.profile?.id] === 'reservation' ?
                                                        'Iscrizione in corso'
                                                        :
                                                        'Sei in lista d\'attesa'
                                                )
                                        }
                                    </h1>
                                </div>

                                : <div className="pl-2 pb-2 w-full flex items-center flex-col md:flex-row bg-[#f2ede8] gap-2">
                                    {
                                        trek && trek.data && trek.data.data && trek.data.data.add_subscriber ?
                                            <button
                                                onClick={() => { setOpenIscriviti(true) }}
                                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                ISCRIVITI
                                            </button>
                                            : ''
                                    }
                                    {/* {
                                        trek && trek.data && trek.data.data && trek.data.data.add_reservation ?
                                            <button
                                                onClick={() => { setOpenPrenotati(true) }}
                                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                PRENOTATI
                                            </button>
                                            : ''
                                    } */}
                                    {
                                        trek && trek.data && trek.data.data && trek.data.data.add_waiting_list ?
                                            <button
                                                onClick={() => { setOpenListaDAttesa(true); }}
                                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                LISTA D'ATTESA
                                            </button>
                                            : ''
                                    }
                                </div>
                        }


                        {/* PERCORSO, VETTORI, TAPPA MEDIA, LUNGHEZZA, ALTITUDINE, DISLIVELLO SALITA */}
                        <div
                            className={
                                "lg:grid flex flex-col lg:grid-cols-2 xl:grid-cols-3 gap-8 mt-8"
                            }>
                            {/* <ItemImageTrek
                                img={destinazione}
                                title={"Percorso"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.daily_program && trek.data.data.daily_program[0] &&
                                    trek.data.data.daily_program[0].day_type
                                    ? trek.data.data.daily_program[0].day_type
                                    : ""
                                }
                            /> */}
                            {/* <ItemImageTrek
                                img={auto}
                                title={"Vettori"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.daily_program && trek.data.data.daily_program[0] &&
                                    trek.data.data.daily_program[0].carrier_ids
                                    ? trek.data.data.daily_program[0].carrier_ids
                                    : ""
                                }
                            /> */}
                            {/* <ItemImageTrek
                                img={tenda}
                                title={"Tappa media"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.trek_duration_gg
                                    ? trek.data.data.technical.trek_duration_gg + "g"
                                    : ""
                                }
                            /> */}
                            <ItemImageTrek
                                img={lunghezza}
                                title={"Lunghezza tot."}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.total_length
                                    ? trek.data.data.technical.total_length
                                    : ""
                                }
                                unita={'km'}
                            />
                            <ItemImageTrek
                                img={altitudine}
                                title={"Altitudine massima"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.altitude_max
                                    ? trek.data.data.technical.altitude_max
                                    : ""
                                }
                                unita={'m'}
                            />

                            <ItemImageTrek
                                img={auto}
                                title={"Vettori"}
                                data={
                                    trek && trek.data && trek.data.data &&
                                    trek.data.data.carrier && trek.data.data.carrier.map((vet, index) => {
                                        return (
                                            <p className="text-sm" key={index}>{vet.name}</p>
                                        )
                                    })
                                }
                            />
                            <ItemImageTrek
                                img={tenda}
                                title={"Tipo pernottamento"}
                                data={
                                    trek.data.data.overnightstay && trek.data.data.overnightstay.map((ons, index) => {
                                        return (
                                            <p className="text-sm" key={index}>{ons.name}</p>
                                        )
                                    })
                                }
                            />
                            {
                                trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.sviluppo_percorso ?
                                    <div className="justify-self-center">
                                        <ItemImageTrek
                                            img={percorso}
                                            title={"Sviluppo Percorso"}
                                            data={
                                                trek && trek.data && trek.data.data &&
                                                    trek.data.data.technical &&
                                                    trek.data.data.technical.sviluppo_percorso
                                                    ? trek.data.data.technical.sviluppo_percorso
                                                    : ""
                                            }
                                        />
                                    </div>
                                    : ''
                            }
                            {/* <ItemImageTrek
                                img={dislivelloMax}
                                title={"Dislivello salita"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.uphill_elevation
                                    ? trek.data.data.technical.uphill_elevation
                                    : ""
                                }
                                unita={'m'}
                            />
                            <ItemImageTrek
                                img={dislivelloMin}
                                title={"Dislivello discesa"}
                                data={trek && trek.data && trek.data.data &&
                                    trek.data.data.technical &&
                                    trek.data.data.technical.downhill_gradient
                                    ? trek.data.data.technical.downhill_gradient
                                    : ""
                                }
                                unita={'m'}
                            /> */}
                        </div>
                    </div>
                    {/* SECONDA COLONNA */}
                    <div className="lg:col-start-3 col-start-1 col-span-1 p-2 sm:p-0">
                        <div className={"mt-12 xl:px-14 2xl:px-24"}>
                            <div
                                className={
                                    "w-full flex flex-row mb-4 items-center justify-center"
                                }>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={
                                        "border text-[#796557] cursor-pointer hover:opacity-75 border-[#796557] p-4 text-2xl w-full items-center flex gap-2 justify-center"
                                    }
                                    onClick={() => {
                                        setOpenAskInfo(true);
                                    }}>
                                    <img src={info} className={"w-8 h-8 inline-flex"} alt={"img"} />
                                    <h3 className="inline-flex">RICHIEDI INFO</h3>
                                </div>
                            </div>
                            <InputBilo
                                className={"text-2xl text-[#796557] text-center mt-12"}
                                title={"Condividi questo trek"}
                            />
                            <div
                                className={
                                    "flex flex-row items-center w-full justify-center mt-4 gap-4"
                                }>
                                <a href={`https://m.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                    <FacebookIcon sx={{ color: "#796557", fontSize: 30 }} />
                                </a>
                                <div className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                    <InstagramIcon sx={{ color: "#796557", fontSize: 30 }} />
                                </div>
                                <a href={`mailto:?body=${encodeURIComponent(`vai al trek: ${window.location.href}`)}`} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                    <EmailOutlinedIcon sx={{ color: "#796557", fontSize: 30 }} />
                                </a>

                            </div>
                            <div
                                className={
                                    "w-full flex flex-row items-center justify-center text-center"
                                }>
                                {/* <div className="h-[250px] lg:h-[450px] w-[100%] overflow-hidden mt-24">
                                    <MapContainer details={trek.data.data.logistic} />
                                </div> */}
                            </div>
                            {/* <div
                                className={
                                    "text-2xl underline text-[#796557] cursor-pointer mt-2 text-center hover:opacity-75 "
                                }>
                                Apri mappa
                            </div> */}
                            <div className={`w-full mt-[10px] max-[1024px]:flex max-[1024px]:justify-center overflow-auto`}>
                                <div className={`${trek && trek.data && trek.data.data && trek.data.data.companion && trek.data.data.companion.length === 1 ? 'w-full' : 'w-max'} grid grid-cols-1 lg:grid-cols-${trek && trek.data && trek.data.data && trek.data.data.companion && trek.data.data.companion.length} lg:gap-[10px] gap-4`}>
                                    {trek && trek.data && trek.data.data && trek.data.data.companion &&
                                        trek.data.data.companion.map((res, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className={"max-w-[335px] min-w-[250px] w-full justify-self-center lg:w-full"}>
                                                        <ItemAccompagnatori
                                                            id={(res.id)}
                                                            sesso={res.sesso === 'femmina' ? 'F' : 'M'}
                                                            img={res['profile_image']}
                                                            name={res.name + " " + res.surname}
                                                            sezione={res.dname.includes('Sezione') ? res.dname.slice(8) : res.dname}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        {/* RICHIESTA INFO */}
                        <Dialog
                            open={openAskInfo}
                            onClose={() => {
                                setOpenAskInfo(false);
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            {count.profile ? (
                                <>
                                    <DialogTitle>Richiesta di Informazioni</DialogTitle>
                                    <form onSubmit={formik.handleSubmit}>
                                        <DialogContent>
                                            <div>
                                                <StyledTextarea
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    maxRows={20}
                                                    placeholder="Info..."
                                                    name="info"
                                                    value={formik.values.info}
                                                    onChange={(e) =>
                                                        formik.setFieldValue("info", e.target.value)
                                                    }
                                                    label="Info"
                                                    {...formik.getFieldProps("info")}
                                                    id="info-textarea"
                                                />
                                                {formik.touched.message && formik.errors.message && (
                                                    <div style={{ color: "red" }}>
                                                        {formik.errors.message}
                                                    </div>
                                                )}
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <button
                                                type="submit"
                                                className={
                                                    "uppercase bg-[#df5b23] p-2 text-md cursor-pointer hover:opacity-50"
                                                }
                                                style={{
                                                    fontFamily: "bilo",
                                                    fontWeight: 500,
                                                    fontStyle: "normal",
                                                    color: "white",
                                                }}>
                                                INVIA
                                            </button>
                                        </DialogActions>
                                    </form>
                                </>
                            ) : (
                                <>
                                    {/* <DialogTitle>
                                        Per visualizzare questo contenuto devi effettuare l’accesso
                                    </DialogTitle> */}
                                    {/* <DialogContent> */}
                                    <Login loginTo={`/trek/${id}`} />
                                    {/* </DialogContent> */}
                                </>
                            )}

                        </Dialog>
                        {/* RICHIESTA INVIATA */}
                        <Dialog
                            open={openAlertForm.open}
                            onClose={() => {
                                setOpenAlertForm(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {openAlertForm.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button
                                    onClick={() => {
                                        setOpenAlertForm(false);
                                    }}
                                    className={
                                        "uppercase bg-[#df5b23] p-2 text-md cursor-pointer hover:opacity-50"
                                    }
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                        color: "white",
                                    }}>
                                    Chiudi
                                </button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* SECONDA RIGA */}
                    <div className="col-start-1 sm:col-span-3 col-span-1 sm:row-start-2 row-start-3 p-2 sm:p-0">
                        {/* NOTE SULA DIFFICOLTA' */}
                        {
                            trek && trek.data && trek.data.data && trek.data.data.technical &&
                            trek.data.data.technical.note &&
                            <>
                                <InputBilo
                                    wrapperTag="h2"
                                    fontWeight={700}
                                    className={"text-2xl mt-10 text-[#796557]"}
                                    title={"NOTE SULLA DIFFICOLTA'"}
                                />
                                <div className={"inline " + styles['prova']} >
                                    {trek && trek.data && trek.data.data && trek.data.data.technical &&
                                        trek.data.data.technical.note &&
                                        parse(trek.data.data.technical.note)}
                                </div>
                            </>
                        }

                        {/* DESCRIZIONE TREK */}
                        {
                            trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.description &&
                            <>
                                <InputGimlet
                                    wrapperTag="h3"
                                    fontStyle="italic"
                                    className={"text-3xl mt-10 text-[#DF5B23]"}
                                    title={'DESCRIZIONE TREK'}
                                />
                                <div className={"inline " + styles['prova']} >
                                    {trek && trek.data && trek.data.data && trek.data.data.trek_note && parse(trek.data.data.trek_note.description)}
                                </div>
                            </>
                        }

                        {/* NOTE SULLA QUOTA DI PARTECIPAZIONE */}
                        {
                            trek && trek.data && trek.data.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.note &&
                            <>
                                <InputGimlet
                                    wrapperTag="h3"
                                    fontStyle="italic"
                                    id="note-quote"
                                    className={"text-3xl mt-12 text-[#DF5B23]"}
                                    title={'NOTE SULLA QUOTA DI PARTECIPAZIONE'}
                                />
                                <div>

                                    <div className={"inline " + styles['prova']} >
                                        {
                                            trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                            (!readMore && trek.data.data.schedulation.note.length > 500 ? parse(trek.data.data.schedulation.note.slice(0, 500)) :
                                                parse(trek.data.data.schedulation.note))
                                        }
                                    </div>

                                    <p
                                        className={"inline text-2xl text-[#796557] "}>
                                        {
                                            trek && trek.data && trek.data.data && trek.data.data.schedulation &&
                                            trek.data.data.schedulation.note && trek.data.data.schedulation.note.length > 500 &&
                                            <a className="contents inline-flex font-bold underline" href="#note-quote" onClick={() => { setReadMore(prev => !prev) }}>{!readMore ? 'leggi di più' : ' leggi di meno'}</a>
                                        }
                                    </p>
                                </div>
                            </>
                        }

                        {/* INFO SUL VIAGGIO */}
                        {
                            trek && trek.data && trek.data.data && trek.data.data.trek_note && (trek.data.data.trek_note.trip_info_end || trek.data.data.trek_note.trip_info_start) &&
                            <>
                                <InputGimlet
                                    wrapperTag="h3"
                                    fontStyle="italic"
                                    className={"text-3xl mt-12 text-[#DF5B23]"}
                                    title={'INFORMAZIONI SUL VIAGGIO'}
                                />
                                {
                                    trek.data.data.trek_note.trip_info_start &&
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}
                                        className={"text-2xl text-[#796557] " + styles['trek']}>
                                        <InputBilo
                                            wrapperTag="span"
                                            fontWeight={700}
                                            className={" mr-1 !text-3xl text-[#796557]"}
                                            title={'ANDATA:'}
                                        />{" "}
                                        <div className={"inline " + styles['prova']} >
                                            {trek.data.data.trek_note &&
                                                parse(trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.trip_info_start)}
                                        </div>
                                    </div>
                                }
                                {
                                    trek.data.data.trek_note.trip_info_end &&
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}
                                        className={"text-2xl mt-10 text-[#796557] whitespace-pre-wrap " + styles['trek']}>
                                        <InputBilo
                                            wrapperTag="span"
                                            fontWeight={700}
                                            className={" mr-1 !text-3xl text-[#796557]"}
                                            title={'RITORNO:'}
                                        />{" "}
                                        <div className={"inline " + styles['prova']} >
                                            {trek.data.data.trek_note &&
                                                parse(trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.trip_info_end)}
                                        </div>
                                    </div>
                                }


                            </>
                        }
                        {/* EQUIPAGGIAMENTO CONSIGLIATO */}
                        {trek && trek.data && trek.data.data && trek.data.data.equipment &&
                            <>
                                <div
                                    style={{ fontFamily: "gimlet-text", fontStyle: 'italic' }}
                                    className={"text-3xl mt-12 text-[#DF5B23]"}>
                                    EQUIPAGGIAMENTO CONSIGLIATO
                                </div>
                                <div className={"flex flex-col text-2xl text-[#796557]"}>
                                    {trek.data.data.equipment &&
                                        trek.data.data.equipment.map((res, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className={"flex flex-row items-center"}>
                                                        <FiberManualRecordIcon
                                                            sx={{ color: "#796557", width: 10 }}
                                                        />
                                                        <div className={"ml-2"}>{res.name}</div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </>
                        }
                        {/* CLIMA E TEMPERATURA */}
                        {trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.note_climate &&
                            <>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"text-3xl mt-10 mb-5 text-[#796557]"}>
                                    CLIMA E TEMPERATURA
                                </div>
                                <div className={"text-2xl text-[#796557]"}>
                                    <div className={"text-[#796557] "}>
                                        {trek && trek.data && trek.data.data && trek.data.data.trek_note &&
                                            trek.data.data.trek_note.note_climate && (
                                                <div className={"inline " + styles['prova']} >
                                                    {parse(trek && trek.data && trek.data.data && trek.data.data.trek_note.note_climate)}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </>
                        }
                        {/* EQUIPAGGIAMENTO CONSIGLIATO */}
                        {trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.note_healthcare &&
                            <>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"text-3xl uppercase mt-10 mb-5 text-[#796557]"}>
                                    note su sanità
                                </div>
                                <div className={"text-2xl text-[#796557]"}>
                                    <div className={"text-[#796557]"}>
                                        {trek && trek.data && trek.data.data && trek.data.data.trek_note &&
                                            trek.data.data.trek_note.note_healthcare && (
                                                <div className={"inline " + styles['prova']} >
                                                    {parse(trek && trek.data && trek.data.data && trek.data.data.trek_note.note_healthcare)}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </>
                        }
                        {/* VISTI E DOCUMENTI */}
                        {trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.note_seen &&
                            <>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"text-3xl uppercase mt-10 mb-5 text-[#796557]"}>
                                    visti e documenti
                                </div>
                                <div className={"text-2xl text-[#796557]"}>
                                    <div className={"text-[#796557]"}>
                                        {trek && trek.data && trek.data.data && trek.data.data.trek_note &&
                                            trek.data.data.trek_note.note_seen && (
                                                <div className={"inline " + styles['prova']} >
                                                    {parse(trek && trek.data && trek.data.data && trek.data.data.trek_note.note_seen)}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </>
                        }
                        {/* VISTI E DOCUMENTI */}
                        {trek && trek.data && trek.data.data && trek.data.data.trek_note && trek.data.data.trek_note.note_various &&
                            <>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"text-3xl mt-10 mb-5 text-[#796557]"}>
                                    NOTE
                                </div>
                                <div className={"text-2xl text-[#796557]"}>
                                    <div className={"text-[#796557]"}>
                                        {trek && trek.data && trek.data.data && trek.data.data.trek_note &&
                                            trek.data.data.trek_note.note_various && (
                                                <div className={"inline " + styles['prova']} >
                                                    {parse(trek && trek.data && trek.data.data && trek.data.data.trek_note.note_various)}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>
                {/* PROGRAMMA GIORNALIERO */}
                <div className={"flex flex-col"}>
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={"text-2xl mt-20 mb-8 text-[#DF5B23]"}>
                        PROGRAMMA GIORNALIERO
                    </div>
                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#796557",
                        }}
                        navigation={true}
                        slidesPerView={1}
                        modules={[Navigation]}
                        className="mySwiper">
                        {trek && trek.data && trek.data.data && trek.data.data.daily_program &&
                            _.uniqBy(trek && trek.data && trek.data.data && trek.data.data.daily_program, "day").map(
                                (res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <SwiperSlide key={index + Math.random()}>
                                                <div className={"h-full w-full"}>
                                                    <div
                                                        className={
                                                            "justify-between lg:h-28 h-28 w-full bg-[#BBC045] gap-4 flex flex-row items-center"
                                                        }>
                                                        <div
                                                            className={
                                                                " bg-white lg:text-2xl text-xl rounded-2xl text-[#796557] p-4 lg:m-4 lg:ml-20 ml-20 items-center"
                                                            }
                                                            style={{ fontFamily: "gimlet-text" }}>
                                                            GIORNO {index + 1}
                                                        </div>
                                                        {
                                                            count && count.profile &&
                                                            <div
                                                                className={
                                                                    "w -full flex flex-col lg:flex-row cursor-pointer hover:opacity-75 items-center lg:mr-20 mr-20 gap-2"
                                                                }>
                                                                <img
                                                                    alt={"img"}
                                                                    src={pdf}
                                                                    className={"w-8 h-8 object-contain"}
                                                                />
                                                                <div onClick={handleDownloadProgramma} className={"text-white whitespace-nowrap text-xl"}>
                                                                    Scarica il programma in PDF
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div
                                                        className={
                                                            "flex flex-col w-full justify-center items-center mt-2 border-2 border-[#f1e9e4]"
                                                        }>
                                                        <div
                                                            className={
                                                                "font-bold lg:text-4xl text-xl text-[#796557] mt-8"
                                                            }>
                                                            {trek && trek.data && trek.data.data && trek.data.data.title && trek.data.data.title}
                                                        </div>
                                                        <div
                                                            className={
                                                                "lg:text-2xl uppercase text-xl text-[#796557]"
                                                            }>
                                                            {res && res.day_type && (res.day_type === 'giornata_libera' ? 'altro' : res.day_type)}
                                                        </div>
                                                        {/* res.detail_day.end_latitude !== '' && res.detail_day.end_longitude !== '' && res.detail_day.start_longitude !== '' && res.detail_day.start_latitude !== '' ? 'lg:grid-cols-2' : '' */}
                                                        {
                                                            res.day_type !== 'giornata_libera' ?
                                                                <div className={`grid grid-cols-1 mb-4 mt-8`}>
                                                                    <div
                                                                        className={
                                                                            "justify-self-center lg:grid flex flex-col lg:grid-cols-2 gap-8"
                                                                        }>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={percorso}
                                                                                title={"Partenza"}
                                                                                data={
                                                                                    res.detail_day && res.detail_day.start_locality ? res.detail_day.start_locality : ""
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={auto}
                                                                                title={"Arrivo"}
                                                                                data={res.detail_day && res.detail_day.end_locality ? res.detail_day.end_locality : ""}
                                                                            />
                                                                        </div>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={clock}
                                                                                title={"Tempo di cammino"}
                                                                                data={res.detail_day && res.detail_day.total_walking_time ? res.detail_day.total_walking_time : ""}
                                                                                unita={'h'}
                                                                            />
                                                                        </div>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={lunghezza}
                                                                                title={"Lunghezza"}
                                                                                data={
                                                                                    res.detail_day && res.detail_day.total_route_length_km
                                                                                        ? res.detail_day.total_route_length_km
                                                                                        : ""
                                                                                }
                                                                                unita={'km'}
                                                                            />
                                                                        </div>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={dislivelloMin}
                                                                                title={"Dislivello discesa"}
                                                                                data={
                                                                                    res.detail_day && (res.detail_day.difference_descent || res.detail_day.difference_descent === 0) ?
                                                                                        res.detail_day.difference_descent : ''
                                                                                }
                                                                                unita={'m'}
                                                                            />
                                                                        </div>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={dislivelloMax}
                                                                                title={"Dislivello salita"}
                                                                                data={
                                                                                    res.detail_day && (res.detail_day.difference_climb || res.detail_day.difference_climb === 0) ?
                                                                                        res.detail_day.difference_climb : ''
                                                                                }
                                                                                unita={'m'}
                                                                            />
                                                                        </div>
                                                                        <div className="justify-self-center">
                                                                            <ItemImageTrek
                                                                                img={altitudine}
                                                                                title={"Altitudine massima"}
                                                                                data={
                                                                                    res.detail_day && (res.detail_day.altitude_max_meter || res.detail_day.altitude_max_meter === 0) ?
                                                                                        res.detail_day.altitude_max_meter : ''
                                                                                }
                                                                                unita={'m'}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            res.detail_day && res.detail_day.route_type && res.detail_day.sviluppo_percorso ?
                                                                                <div className="justify-self-center">
                                                                                    <ItemImageTrek
                                                                                        img={percorso}
                                                                                        title={"Sviluppo Percorso"}
                                                                                        data={
                                                                                            res.detail_day && res.detail_day.route_type && res.detail_day.sviluppo_percorso ?
                                                                                                res.detail_day.sviluppo_percorso : ''
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                    {/* {
                                                                res.detail_day.end_latitude !== '' &&
                                                                res.detail_day.end_longitude !== '' &&
                                                                res.detail_day.start_longitude !== '' &&
                                                                res.detail_day.start_latitude !== '' &&
                                                                <div className="justify-self-center h-[250px] lg:h-[450px] w-[100%] max-w-[450px] overflow-hidden mt-8 lg:mt-0">
                                                                    <MapContainer details={{
                                                                        latitude_end: res.detail_day.end_latitude,
                                                                        latitude_start: res.detail_day.start_latitude,
                                                                        longitude_end: res.detail_day.end_longitude,
                                                                        longitude_start: res.detail_day.start_longitude,
                                                                    }}
                                                                    />
                                                                </div>
                                                            } */}

                                                                </div>
                                                                : ''
                                                        }
                                                    </div>
                                                    <div
                                                        className={
                                                            "text-left w-full flex mt-2 flex-col bg-[#f1e9e4] p-4"
                                                        }>
                                                        <div
                                                            className={"text-[#796557] text-2xl font-bold"}>
                                                            DESCRIZIONE TAPPA
                                                        </div>
                                                        <div className={"text-2xl text-[#796557]"}>
                                                            <div className={"text-[#796557]"}>
                                                                {res.description_day && (
                                                                    <div
                                                                        style={{
                                                                            fontFamily: "bilo",
                                                                            fontStyle: "normal",
                                                                        }}
                                                                        className={`${styles['prova']}`}
                                                                    >
                                                                        {parse(res.description_day)}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                "mt-4 text-[#796557] text-2xl font-bold"
                                                            }>
                                                            ITINERARIO
                                                        </div>
                                                        <div className={"text-2xl text-[#796557]"}>
                                                            <div className={"text-[#796557]"}>
                                                                {res.itinerary && (
                                                                    <div
                                                                        style={{
                                                                            fontFamily: "bilo",
                                                                            fontStyle: "normal",
                                                                        }}
                                                                        className={`styles['prova']`}
                                                                    >
                                                                        {parse(res.itinerary)}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </React.Fragment>
                                    );
                                }
                            )}
                    </Swiper>
                </div>
                {/* DOMANDE E RISPOSTE */}
                <div>
                    <div className="flex items-center justify-center">
                        <div className="border border-[#A9A298] w-[10%]"></div>
                        <InputGimlet
                            className="text-2xl py-2 text-[#DF5B23] m-[30px]"
                            title={"DOMANDE E RISPOSTE"}
                        />
                        <div className="border border-[#A9A298] w-[10%]"></div>
                    </div>
                    <div className="flex flex-col w-full items-center">
                        {ask &&
                            ask.map((res, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="flex justify-start mb-[62px] w-[95%]">
                                            <div className="rounded-full w-[50px] h-[50px] bg-[#f1e9e4] mr-[20px]">
                                                <PersonIcon style={{ color: "white", fontSize: 50 }} />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <div className="bg-[#EAEAEA] flex justify-between items-center">
                                                    <InputBilo
                                                        title={"DOMANDA"}
                                                        className={"text-xl p-3 text[#796557]"}
                                                        fontWeight={700}
                                                    />

                                                    {openRisposta === index ? (
                                                        <KeyboardArrowUpIcon
                                                            sx={{ marginRight: "10px" }}
                                                            onClick={() => {
                                                                setOpenRisposta(null);
                                                            }}
                                                        />
                                                    ) : (
                                                        <KeyboardArrowDownIcon
                                                            sx={{ marginRight: "10px" }}
                                                            onClick={() => {
                                                                setOpenRisposta(index);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div className="pb-5 px-5 text-lg">
                                                    <InputBilo
                                                        title={res.domanda}
                                                        className={"text-xl p-3"}
                                                    />
                                                </div>
                                                <div>
                                                    <div
                                                        className={`flex justify-start bg-[#1F2F2226] p-5 border border-t-[#A9A298] ${openRisposta === index ? "" : "hidden"
                                                            }`}>
                                                        <div className="rounded-full w-[40px] h-[40px] bg-[#f1e9e4] mr-[20px]">
                                                            <img src="" alt="" />
                                                        </div>
                                                        <div className="w-full">
                                                            <InputBilo
                                                                title={"RISPOSTA DI"}
                                                                className={"text-xl text[#796557]"}
                                                                fontWeight={700}
                                                            />
                                                            <InputBilo
                                                                className={"text-xl text-[#DF5B23]"}
                                                                title={"TREKKING ITALIA"}
                                                                fontWeight={700}
                                                            />
                                                            <div className="py-5">
                                                                <InputBilo
                                                                    title={
                                                                        res.risposta ? parse(res.risposta.risposta) : ""
                                                                    }
                                                                    className={"text-xl whitespace-pre-wrap " + styles['trek'] + ' !leading-3'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
            {
                count && count.profile ?
                    <>
                        {/* ISCRIVITI */}
                        <Dialog
                            open={openIscriviti}
                            onClose={() => {
                                setOpenIscriviti(false);
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <>
                                <DialogTitle>
                                    Iscrizione
                                </DialogTitle>
                                <DialogContent>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-2 pr-[95px]">
                                        <div className="flex">
                                            <InputBilo
                                                title={`Nome: ${count.profile.name}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Cognome: ${count.profile.surname}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`email: ${count.profile.email}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Codice Fiscale: ${count.profile.fiscal_code}`}
                                                className={'text-lg'} />
                                        </div>
                                    </div>
                                    {
                                        idMultiIscrizioni && idMultiIscrizioni.map((user, index) => {
                                            return (
                                                <div className="flex gap-2 items-center">
                                                    <div key={index} className="w-full mt-2 grid grid-cols-2 gap-4 border-2">
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Nome: ${user.nome}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Cognome: ${user.surname}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        {/* <div className="flex">
                                                            <InputBilo
                                                                title={`email: ${user.email}`}
                                                                className={'text-lg'} />
                                                        </div> */}
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Codice Fiscale: ${user.codice_fiscale}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                return prevState.filter((value, indexPrev) => {
                                                                    return indexPrev !== index
                                                                })
                                                            })
                                                        }}
                                                        className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="mt-5">
                                        <button
                                            onClick={() => { setOpenFormSearchUser(true) }}
                                            className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                            style={{
                                                fontFamily: "gimlet-text",
                                                fontWeight: 500,
                                                fontStyle: "italic",
                                            }}>
                                            + Aggiungi Utente
                                        </button>
                                    </div>
                                    {openFormSearchUser &&
                                        <>
                                            <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 w-full items-end gap-4 mt-5">
                                                <InputCustom
                                                    name={"nome"}
                                                    value={searchUser.nome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            nome: e.target.value
                                                        }
                                                    })}
                                                    title={"Nome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"cognome"}
                                                    value={searchUser.cognome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            cognome: e.target.value
                                                        }
                                                    })}
                                                    title={"cognome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"email"}
                                                    value={searchUser.email}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            email: e.target.value
                                                        }
                                                    })}
                                                    title={"Email"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"fiscal_code"}
                                                    value={searchUser.codice_fiscale}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            codice_fiscale: e.target.value
                                                        }
                                                    })}
                                                    title={"Codice Fiscale"}
                                                    fullWidth
                                                />
                                                <button
                                                    onClick={() => {
                                                        setUsersFound([])
                                                        api.post('search-user-for-subscription', searchUser).then((res) => {
                                                            setUsersFound(res.data.data.map((user) => {
                                                                return {
                                                                    nome: user.name,
                                                                    surname: user.surname,
                                                                    email: user.email,
                                                                    codice_fiscale: user.fiscal_code,
                                                                    id: user.id
                                                                }
                                                            }))
                                                            return res
                                                        });
                                                    }}
                                                    type={"button"}
                                                    className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}>
                                                    CERCA
                                                </button>
                                            </div>
                                            {
                                                usersFound.length > 0 &&
                                                <div className="flex gap-2">
                                                    <InputCustom
                                                        id="select_user"
                                                        select
                                                        defaultValue='-'
                                                        fullWidth
                                                        name={"id_user"}
                                                        sx={{
                                                            '& .MuiSelect-select': {
                                                                display: 'flex',
                                                                gap: 2
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setUserSelected(e.target.value)
                                                        }}
                                                    >
                                                        <MenuItem disabled={true} key={'-'} value={'-'} className="w-full p-2 gap-2 flex justiify-center">
                                                            <InputBilo
                                                                className={'whitespace-pre'}
                                                                title={'Clicca per selezionare un utente'}
                                                            />
                                                        </MenuItem>
                                                        {usersFound.map((user, index) => {
                                                            return (
                                                                <MenuItem key={index} value={user} className="w-full p-2 gap-2 flex justiify-center">
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.nome}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.surname}
                                                                    />
                                                                    {/* <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.email}
                                                                    /> */}
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.codice_fiscale}
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </InputCustom>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                if (prevState.some((value) => { return value.id === userSelected.id })) {
                                                                    return [...prevState]
                                                                } else {
                                                                    return [
                                                                        ...prevState,
                                                                        userSelected
                                                                    ]
                                                                }
                                                            })
                                                            setOpenFormSearchUser(false)
                                                        }}
                                                        type={"button"}
                                                        className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        Aggiungi
                                                    </button>
                                                </div>
                                            }
                                        </>

                                    }
                                    {
                                        trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quote_trek === 0 ?
                                            <button
                                                className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                                onClick={() => {
                                                    handleClickPagamento()
                                                }}>
                                                Procedi all'iscrizione
                                            </button>
                                            :
                                            (
                                                trek && trek.data && trek.data.data && trek.data.data.format && trek.data.data.format !== 'Giornaliero' ?
                                                    <button
                                                        className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}
                                                        onClick={() => {
                                                            setOpenIscriviti(false);
                                                            if (trek && trek.data && trek.data.data) {
                                                                if (trek.data.data.can_pay_advance && trek.data.data.can_pay_balance) {
                                                                    setOpenAnticipoOrSaldo(true)
                                                                } else {
                                                                    setOpenPaymentMethod(true)
                                                                    setImporto(trek.data.data.can_pay_advance ? trek.data.data.schedulation.advance_fee : (trek.data.data.can_pay_balance ? trek.data.data.schedulation.quote_trek : 0))
                                                                    setIsAnticipo(trek.data.data.can_pay_balance ? false : true)
                                                                }
                                                            }


                                                        }}>
                                                        Pagamento online
                                                    </button>
                                                    :
                                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  items-stretch gap-2">
                                                        {
                                                            trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quota_management &&
                                                            (
                                                                (trek.data.data.schedulation.quota_management === 'advance_balance' && trek.data.data.schedulation.advance_payment_methods && trek.data.data.schedulation.advance_payment_methods.includes('Online'))
                                                                ||
                                                                (trek.data.data.schedulation.quota_management === 'balance' && trek.data.data.schedulation.balance_payment_methods && trek.data.data.schedulation.balance_payment_methods.includes('Online'))
                                                            ) &&
                                                            <button
                                                                className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                                style={{
                                                                    fontFamily: "gimlet-text",
                                                                    fontWeight: 500,
                                                                    fontStyle: "italic",
                                                                }}
                                                                onClick={() => {
                                                                    if (trek && trek.data && trek.data.data) {
                                                                        if (trek.data.data.can_pay_advance && trek.data.data.can_pay_balance) {
                                                                            setOpenAnticipoOrSaldo(true)
                                                                        } else {
                                                                            setOpenPaymentMethod(true)
                                                                            setImporto(trek.data.data.can_pay_advance ? trek.data.data.schedulation.advance_fee : (trek.data.data.can_pay_balance ? trek.data.data.schedulation.quote_trek : 0))
                                                                            setIsAnticipo(trek.data.data.can_pay_balance ? false : true)
                                                                        }
                                                                    }
                                                                    setOpenIscriviti(false);
                                                                }}>
                                                                Pagamento online
                                                            </button>
                                                        }
                                                        {
                                                            trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quota_management &&
                                                            (
                                                                (trek.data.data.schedulation.quota_management === 'advance_balance' && trek.data.data.schedulation.advance_payment_methods && trek.data.data.schedulation.advance_payment_methods.includes('Bonifico'))
                                                                ||
                                                                (trek.data.data.schedulation.quota_management === 'balance' && trek.data.data.schedulation.balance_payment_methods && trek.data.data.schedulation.balance_payment_methods.includes('Bonifico'))
                                                            ) &&
                                                            <button
                                                                className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                                style={{
                                                                    fontFamily: "gimlet-text",
                                                                    fontWeight: 500,
                                                                    fontStyle: "italic",
                                                                }}
                                                                onClick={() => {
                                                                    handleClickPagamento('Bonifico')
                                                                }}>
                                                                Bonifico
                                                            </button>
                                                        }
                                                        {
                                                            trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quota_management &&
                                                            (
                                                                (trek.data.data.schedulation.quota_management === 'advance_balance' && trek.data.data.schedulation.advance_payment_methods && trek.data.data.schedulation.advance_payment_methods.includes('Pagamento in sede'))
                                                                ||
                                                                (trek.data.data.schedulation.quota_management === 'balance' && trek.data.data.schedulation.balance_payment_methods && trek.data.data.schedulation.balance_payment_methods.includes('Pagamento in sede'))
                                                            ) &&
                                                            <button
                                                                className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                                style={{
                                                                    fontFamily: "gimlet-text",
                                                                    fontWeight: 500,
                                                                    fontStyle: "italic",
                                                                }}
                                                                onClick={() => {
                                                                    handleClickPagamento('Pagamento in sede')
                                                                }}>
                                                                Pagamento in sede
                                                            </button>
                                                        }
                                                        {
                                                            trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quota_management &&
                                                            (
                                                                (trek.data.data.schedulation.quota_management === 'advance_balance' && trek.data.data.schedulation.advance_payment_methods && trek.data.data.schedulation.advance_payment_methods.includes('Pagamento alla partenza'))
                                                                ||
                                                                (trek.data.data.schedulation.quota_management === 'balance' && trek.data.data.schedulation.balance_payment_methods && trek.data.data.schedulation.balance_payment_methods.includes('Pagamento alla partenza'))
                                                            ) &&
                                                            <button
                                                                className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                                style={{
                                                                    fontFamily: "gimlet-text",
                                                                    fontWeight: 500,
                                                                    fontStyle: "italic",
                                                                }}
                                                                onClick={() => {
                                                                    handleClickPagamento('Pagamento alla partenza')
                                                                }}>
                                                                Pagamento alla partenza
                                                            </button>
                                                        }
                                                    </div>
                                            )
                                    }
                                </DialogContent>
                            </>

                        </Dialog >
                        {/* SCEGLI SE PAGARE L'ANTICIPO O IL SALDO */}
                        <DialogCustom
                            open={openAnticipoOrSaldo}
                            title={'Scegli'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={'Preferisci pagare l\'anticipo o l\'Importo intero?'} />
                                </>
                            }
                            handleClose={() => {

                                setImporto(trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.advance_fee)
                                setIsAnticipo(true)
                                setOpenPaymentMethod(true)
                                setOpenAnticipoOrSaldo(false)
                            }}
                            closeModal={() => {
                                setOpenAnticipoOrSaldo(false)
                            }}
                            handleClose2={() => {
                                setImporto(trek && trek.data && trek.data.data && trek.data.data.schedulation && trek.data.data.schedulation.quote_trek)
                                setIsAnticipo(false)
                                setOpenPaymentMethod(true)
                                setOpenAnticipoOrSaldo(false)
                            }}
                            button={'Anticipo'}
                            button2={'Importo intero'}
                        />
                        {/* SCEGLI METODO DI PAGAMENTO ISCRIZIONE*/}
                        <DialogCustom
                            open={openPaymentMethod}
                            title={'Scegli'}
                            content={
                                <>
                                    <InputBilo className={'text-2xl'} title={`Importo: €${trek && trek.data && trek.data.data ? `${(importo + (importo * idMultiIscrizioni.length)).toFixed(2)}` : ''} `} />
                                    <InputBilo className={'text-xl'} title={env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'} />
                                </>
                            }
                            handleClose={() => {
                                api.post(`subscriptions/prepareWalletSubscription`, {
                                    user_id: count.profile.id,
                                    id: trek && trek.data && trek.data.data ? trek.data.data.trek_subscription_id : '',
                                    type: 'trek',
                                    balance: trek && trek.data && trek.data.data ? `${(Number(importo) + (Number(importo) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                    userIDs: idMultiIscrizioni.map(user => user.id),
                                    only_advance: isAnticipo
                                }).then((res) => {
                                    localStorage.setItem('lastpage', location.pathname)
                                    if (res && res.data && res.data.data) {
                                        navigate(`/payment-success${res.data.data.newyear_membership && res.data.data.open_membership ? `?newyear_membership=${res.data.data.newyear_membership}&open_membership=${res.data.data.open_membership}` : ''}`)
                                    } else {
                                        setError({
                                            open: true,
                                            error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                                        })
                                    }
                                    return res
                                });
                                setOpenPaymentMethod(false)
                            }}
                            closeModal={() => {
                                setOpenPaymentMethod(false)
                            }}
                            handleClose2={() => {
                                api.post(`subscriptions/requestWithoutWallet`, {
                                    user_id: count.profile.id,
                                    id: trek && trek.data && trek.data.data ? trek.data.data.trek_subscription_id : '',
                                    type: 'trek',
                                    balance: trek && trek.data && trek.data.data ? `${(Number(importo) + (Number(importo) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                    userIDs: idMultiIscrizioni.map(user => user.id),
                                    only_advance: isAnticipo
                                }).then((res) => {
                                    localStorage.setItem('lastpage', location.pathname)
                                    if (res && res.data && res.data.data) {

                                        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                                        // Controllo per Iphone
                                        if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
                                            setIsIphone(true)
                                            setLinkForIphone(res.data.data)
                                        } else {
                                            let a = document.createElement("a");
                                            document.body.appendChild(a);
                                            a.style = "display: none";
                                            a.href = res.data.data;
                                            a.target = '_blank'
                                            a.click();
                                            document.body.removeChild(a);

                                        }
                                    } else {
                                        setError({
                                            open: true,
                                            error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                                        })
                                    }
                                    return res
                                });

                                setOpenPaymentMethod(false)
                            }}
                            button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                            button2={'Carta'}
                        />
                        <DialogCustom
                            open={isIphone}
                            title={"Apertura di un'altra finestra"}
                            content={<h2>Stai per lasciare questa pagina, continuare?</h2>}
                            handleClose2={() => {
                                window.location.reload()
                                setIsIphone(false)
                            }}
                            handleClose={() => {
                                setIsIphone(false)
                                window.open(linkForIphone)
                            }}
                            closeModal={() => { setIsIphone(false) }}
                            button={'SI'}
                            button2={'NO'}
                        />
                        {/* ERROR ISCRIVITI */}
                        <DialogCustom
                            open={error.open}
                            title={'Errore'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={error.error} />
                                </>
                            }
                            button={'Chiudi'}
                            handleClose={() => {
                                (setError(prevState => {
                                    return {
                                        open: false,
                                        error: ''
                                    }
                                }))
                            }}
                            closeModal={() => {
                                (setError(prevState => {
                                    return {
                                        open: false,
                                        error: ''
                                    }
                                }))
                            }}
                        />

                        {/* PRENOTATI */}
                        <DialogCustom
                            open={openPrenotati}
                            title={'Prenotati'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={
                                        'Sicuro di volerti iscrivere a questo trek?'
                                    } />
                                </>
                            }
                            button={'No'}
                            button2={'Si'}
                            handleClose={() => {
                                setOpenPrenotati(false)
                            }}
                            closeModal={() => {
                                setOpenPrenotati(false)
                            }}
                            handleClose2={() => {
                                api.post('subscriptions/add-reservation', {
                                    user_id: count.profile.id,
                                    id: trek && trek.data && trek.data.data ? trek.data.data.trek_subscription_id : '',
                                    type: 'trek',
                                    balance: importo,
                                }).then(res => {
                                    if (res.data.errors) {
                                        setError({
                                            open: true,
                                            error: res.data.errors.user[0]
                                        })
                                    } else {
                                        setSuccess({
                                            open: true,
                                            message: 'La informiamo che la sua prenotazione è andata a buon fine'
                                        })
                                    }

                                    return res
                                })


                                setOpenPrenotati(false)
                            }}
                        />

                        {/* LISTA D'ATTESA */}

                        <Dialog
                            open={openListaDAttesa}
                            onClose={() => {
                                setOpenListaDAttesa(false);
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <>
                                <DialogTitle>
                                    Iscrizione
                                </DialogTitle>
                                <DialogContent>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-2 pr-[95px]">
                                        <div className="flex">
                                            <InputBilo
                                                title={`Nome: ${count.profile.name}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Cognome: ${count.profile.surname}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`email: ${count.profile.email}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Codice Fiscale: ${count.profile.fiscal_code}`}
                                                className={'text-lg'} />
                                        </div>
                                    </div>
                                    {
                                        idMultiIscrizioni && idMultiIscrizioni.map((user, index) => {
                                            return (
                                                <div className="flex gap-2 items-center">
                                                    <div key={index} className="w-full mt-2 grid grid-cols-2 gap-4 border-2">
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Nome: ${user.nome}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Cognome: ${user.surname}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        {/* <div className="flex">
                                                            <InputBilo
                                                                title={`email: ${user.email}`}
                                                                className={'text-lg'} />
                                                        </div> */}
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Codice Fiscale: ${user.codice_fiscale}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                return prevState.filter((value, indexPrev) => {
                                                                    return indexPrev !== index
                                                                })
                                                            })
                                                        }}
                                                        className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="mt-5">
                                        <button
                                            onClick={() => { setOpenFormSearchUser(true) }}
                                            className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                            style={{
                                                fontFamily: "gimlet-text",
                                                fontWeight: 500,
                                                fontStyle: "italic",
                                            }}>
                                            + Aggiungi Utente
                                        </button>
                                    </div>
                                    {openFormSearchUser &&
                                        <>
                                            <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 w-full items-end gap-4 mt-5">
                                                <InputCustom
                                                    name={"nome"}
                                                    value={searchUser.nome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            nome: e.target.value
                                                        }
                                                    })}
                                                    title={"Nome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"cognome"}
                                                    value={searchUser.cognome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            cognome: e.target.value
                                                        }
                                                    })}
                                                    title={"cognome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"email"}
                                                    value={searchUser.email}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            email: e.target.value
                                                        }
                                                    })}
                                                    title={"Email"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"fiscal_code"}
                                                    value={searchUser.codice_fiscale}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            codice_fiscale: e.target.value
                                                        }
                                                    })}
                                                    title={"Codice Fiscale"}
                                                    fullWidth
                                                />
                                                <button
                                                    onClick={() => {
                                                        setUsersFound([])
                                                        api.post('search-user-for-subscription', searchUser).then((res) => {
                                                            setUsersFound(res.data.data.map((user) => {
                                                                return {
                                                                    nome: user.name,
                                                                    surname: user.surname,
                                                                    email: user.email,
                                                                    codice_fiscale: user.fiscal_code,
                                                                    id: user.id
                                                                }
                                                            }))
                                                            return res
                                                        });
                                                    }}
                                                    type={"button"}
                                                    className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}>
                                                    CERCA
                                                </button>
                                            </div>
                                            {
                                                usersFound.length > 0 &&
                                                <div className="flex gap-2">
                                                    <InputCustom
                                                        id="select_user"
                                                        select
                                                        fullWidth
                                                        defaultValue='-'
                                                        name={"id_user"}
                                                        sx={{
                                                            '& .MuiSelect-select': {
                                                                display: 'flex',
                                                                gap: 2
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setUserSelected(e.target.value)
                                                        }}
                                                    >
                                                        <MenuItem disabled={true} key={'-'} value={'-'} className="w-full p-2 gap-2 flex justiify-center">
                                                            <InputBilo
                                                                className={'whitespace-pre'}
                                                                title={'Clicca per selezionare un utente'}
                                                            />
                                                        </MenuItem>
                                                        {usersFound.map((user, index) => {
                                                            return (
                                                                <MenuItem key={index} value={user} className="w-full p-2 gap-2 flex justiify-center">
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.nome}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.surname}
                                                                    />
                                                                    {/* <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.email}
                                                                    /> */}
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.codice_fiscale}
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </InputCustom>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                if (prevState.some((value) => { return value.id === userSelected.id })) {
                                                                    return [...prevState]
                                                                } else {
                                                                    return [
                                                                        ...prevState,
                                                                        userSelected
                                                                    ]
                                                                }
                                                            })
                                                            setOpenFormSearchUser(false)
                                                        }}
                                                        type={"button"}
                                                        className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        Salva
                                                    </button>
                                                </div>
                                            }
                                        </>
                                    }
                                    <button
                                        className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                        style={{
                                            fontFamily: "gimlet-text",
                                            fontWeight: 500,
                                            fontStyle: "italic",
                                        }}
                                        onClick={() => {
                                            api.post('subscriptions/add-waiting', {
                                                user_id: count.profile.id,
                                                id: trek && trek.data && trek.data.data ? trek.data.data.trek_subscription_id : '',
                                                type: 'trek',
                                                userIDs: idMultiIscrizioni.map(user => user.id),
                                            }).then(res => {
                                                if (res.data.errors) {
                                                    setError({
                                                        open: true,
                                                        error: res.data.errors.user[0]
                                                    })
                                                } else {
                                                    setSuccess({
                                                        open: true,
                                                        message: 'La informiamo che ha effettuato correttamente l\'iscrizione alla lista d\'attesa'
                                                    })
                                                }

                                                return res
                                            })
                                            setOpenListaDAttesa(false);
                                        }}>
                                        Lista d'attesa
                                    </button>
                                </DialogContent>
                            </>
                        </Dialog>

                        {/* Success avvenuta prenotazione/riservazione */}
                        <Dialog
                            open={success.open}
                            onClose={() => {
                                setSuccess({
                                    open: false,
                                    message: ''
                                });
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <DialogTitle>
                                Prenotazione effettuata
                            </DialogTitle>
                            <DialogContent>
                                <InputBilo
                                    className={'text-2xl mb-2'}
                                    title={success.message}
                                />
                                <button
                                    onClick={() => {
                                        setSuccess({
                                            open: false,
                                            message: ''
                                        })
                                    }}
                                    className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                    style={{
                                        fontFamily: "gimlet-text",
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                    }}>
                                    Chiudi
                                </button>
                            </DialogContent>
                        </Dialog>
                    </>
                    :
                    <Dialog
                        open={openIscriviti || openPrenotati || openListaDAttesa}
                        onClose={() => {
                            setOpenIscriviti(false);
                            setOpenPrenotati(false);
                            setOpenListaDAttesa(false);
                        }}
                        maxWidth={"lg"}
                        fullWidth={true}>
                        <>
                            {/* <DialogTitle>
                                Per visualizzare questo contenuto devi effettuare l’accesso
                            </DialogTitle> */}
                            {/* <DialogContent> */}
                            <Login loginTo={`/trek/${id}`} />
                            {/* </DialogContent> */}
                        </>

                    </Dialog>
            }
        </>
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(Trek);
